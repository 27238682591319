import { BaseStore } from './base.store';
import { RootStore } from './index';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UIAnomaliesFilter } from '../../dtos/anomalies.dto';
import { convertSavedFilterToMap } from '../../utils/parse';
import useCaseService from '../../services/use-case.service';

export class AnomaliesStore extends BaseStore {
    filterMap: Map<string, UIAnomaliesFilter> = new Map([]);
    worldRegions: any[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            filterMap: observable,
            filter: computed,
            setFilter: action,
            currentFilterKey: computed,
            initializeFilterMap: action,
            initWorldRegions: action,
            worldRegions: observable,
        });

        this.initializeFilterMap();
    }

    initializeFilterMap() {
        const storedFilterMap = localStorage.getItem('anomaliesFilterMap');
        if (storedFilterMap) {
            this.filterMap = convertSavedFilterToMap(storedFilterMap);
        } else {
            this.filterMap = new Map([]);
        }
    }

    get filter(): UIAnomaliesFilter {
        if (!this.currentFilterKey || !this.rootStore.useCaseStore.currentUseCaseValues) return null;

        if (!this.filterMap.get(this.currentFilterKey)) {
            let dateRangeDefault = 'Last 7 days';
            if (this.rootStore.useCaseStore.useCaseDayOffsetFilter === 2) {
                dateRangeDefault = 'Past 10 days';
            } else if (this.rootStore.useCaseStore.useCaseDayOffsetFilter === 1) {
                dateRangeDefault = 'Previous 7 days';
            }
            this.setFilter({dateRange: dateRangeDefault, apn: this.rootStore.useCaseStore.apnPreferences?.[0]?.apnName || '',
                worldRegion: this.worldRegions?.[0]?.name || '',
                isShowPrivateIp: true,
                ratType: this.rootStore.customerStore.customerRatTypes?.[0]});
        }
        return this.filterMap.get(this.currentFilterKey);
    }

    public setFilter(filter: UIAnomaliesFilter) {
        if (this.currentFilterKey) {
            this.filterMap.set(this.currentFilterKey, filter);
            localStorage.setItem('anomaliesFilterMap', JSON.stringify(Array.from(this.filterMap.entries())));
        }
    }

    get currentFilterKey(): string {
        if (this.rootStore.useCaseStore.isLoading) return null;
        const customerName = this.rootStore.customerStore.selectedCustomer?.name;
        const useCaseName = this.rootStore.useCaseStore.currentUseCaseName;
        if (customerName && useCaseName) {
            return `${customerName}-${useCaseName}`
        }
        return null;
    }

    public async initWorldRegions() {
        const res = await useCaseService.getWorldRegions();
        runInAction(() => {
            this.worldRegions = res;
        });
    }
}

import './grafana-widget.scss';
import { observer } from 'mobx-react-lite';
import { Dropdown, Menu, Modal } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as Expand } from '../../../assets/svg/full-screen-37.svg';
import LoaderComponent from '../../shared/loader/loader-component';
import { useStores } from '../../store';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download.svg';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning.svg';
import { createCsvFile } from '../../../utils/file-saver';
import { ErrorCode } from '../../../utils/enums';

export interface GrafanaWidgetProps {
    src: string;
    expanded?: boolean;
    panelId?: string;
    topPanelSrc?: string;
    onLinkClick?(panelId: string, href: string): void;
}

export const GrafanaWidget = observer((props: GrafanaWidgetProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [textExists, setTextExists] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [framesData, setFramesData] = useState<{ [key: string]: { frames: Array<{ schema: any; data: any }> } }>({});
    const [isError, setIsError] = useState(false);
    const [modalSrc, setModalSrc] = useState(props.src);
    const {uxStore, userStore, customerStore} = useStores();
    const iframeRef = useRef(null);

    useEffect(() => {
        setShowLoader(true);
        setTextExists(false);
        setFramesData({});
        setIsError(false);
        setModalSrc(props.src);
    }, [props.src]);

    useEffect(() => {
        const handleReceiveMessage = (event) => {
            const {panelId, tabId, frames, referrer, error} = event.data;
            if (panelId === props.panelId && uxStore.getUniqueId === tabId) {
                handleEventError(error);
                handleEventFrames(frames);
                handleEventReferrer(referrer);
            }
        };

        navigator.serviceWorker?.addEventListener('message', handleReceiveMessage);
        return () => {
            navigator.serviceWorker?.removeEventListener('message', handleReceiveMessage);
        };
    }, [props.panelId]);

    function handleEventError(error) {
        if (error) {
            if (error === ErrorCode.UNAUTHENTICATED_401) {
                userStore.load();
            } else {
                setIsError(true);
                setShowLoader(false);
            }
        }
    }

    function handleEventFrames(frames) {
        if (frames) {
            setFramesData(frames);
            setIsError(false);
        }
    }

    function handleEventReferrer(referrer) {
        const iframeModalClassElement = document.querySelector('.widget-modal');
        if (referrer &&
            (!iframeModalClassElement || (iframeModalClassElement as any).style.display === 'none')) {
            setModalSrc(referrer.substring(referrer.indexOf('/grafana')));
        }
    }

    useEffect(() => {
        const textToCheck = 'Loading & initializing dashboard';
        let intervalId;

        const checkForText = () => {
            const iframeElements = iframeRef.current?.querySelectorAll('iframe');
            let textFound = false;
            iframeElements?.forEach((iframeElement) => {
                const iframeContent = iframeElement.contentWindow?.document;
                const divText = iframeContent.body?.innerText || iframeContent.body?.textContent;
                if (divText?.includes(textToCheck)) {
                    textFound = true;
                    setTextExists(true);
                }
            });
            if (textExists && !textFound) {
                clearInterval(intervalId);
                fixGrafanaCss(iframeElements);
                if (props.onLinkClick) {
                    setIframeLinksObserver();
                }
                setShowLoader(false);
            }
        };

        intervalId = setInterval(checkForText, 1000);

        return () => clearInterval(intervalId);
    }, [textExists]);

    function fixGrafanaCss(iframeElements: any) {
        iframeElements?.forEach((iframeElement) => {
            const iframeContent = iframeElement.contentWindow?.document;
            // Hide error messages in the iframe
            const pageAlertList = iframeContent.querySelector('.page-alert-list');
            if (pageAlertList) {
                pageAlertList.style.visibility = 'hidden';
            }

            // Replace move cursor with default on the widget title row
            const panelTitleContainer = iframeContent.querySelector('.panel-title-container');
            if (panelTitleContainer) {
                panelTitleContainer.style.cursor = 'default';
            }

            // Replace pointer cursor with default on the widget title text
            const h2 = iframeContent.querySelector('h2');
            if (h2) {
                h2.style.cursor = 'default';
            }

            // remove background color when hovering the widget title
            const panelHeader = iframeContent.querySelector('.panel-header');
            if (panelHeader) {
                panelHeader.onmouseover = function () {
                    this.style.backgroundColor = 'transparent';
                }
                panelHeader.onmouseout = function () {
                    this.style.backgroundColor = '';
                }
            }
        });
    }

    function setIframeLinksObserver() {
        const baseElement = props.expanded ? document.querySelector('.widget-modal') : document;
        const iframe: any = Array.from(baseElement.querySelectorAll('.grafana-iframe')).find((iframe: any) => iframe.src.includes(`panelId=${props.panelId}`));
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.addedNodes.length) {
                    mutation.addedNodes.forEach(node => {
                        alterContextMenuElements(node);
                    })
                }
            }
        });
        observer.observe(iframeDoc, {childList: true, subtree: true});
    }

    function alterContextMenuElements(node: Node) {
        const element = node as HTMLElement;
        const a = element.querySelector?.('a');
        if (a) {
            if (props.expanded) {
                element.removeChild(a);
            } else {
                a.onclick = function () {
                    props.onLinkClick(props.panelId, a.href);
                    return false;
                }
            }
        }
        const contextMenu: any = element.querySelector?.('[aria-label="Context menu"]');
        if (contextMenu) {
            const top = contextMenu.style.top.replace(/\D/g, '');
            contextMenu.style.top = `${top - 81}px`;
        }
    }

    const handleExportClick = (key) => {
        const fields = framesData[key].frames[0].schema.fields;
        const frameValues = framesData[key].frames[0].data.values;

        const rows = frameValues[0].map((_, i) => frameValues.map(row => row[i]));

        const csvData = rows.map(row =>
            row.reduce((rowData, value, i) => {
                const { name, type } = fields[i];

                if (type === 'time') {
                    value = moment.utc(value).format('DD/MM/YYYY HH:mm:ss');
                }

                return { ...rowData, [name]: value };
            }, {})
        );

        const iframeElements = iframeRef.current?.querySelectorAll('iframe');
        const iframe = iframeElements.length === 1 ? iframeElements[0] : iframeElements[1];
        const iframeContent = iframe?.contentWindow?.document;
        const panelTitleText = iframeContent.querySelector('.panel-title').textContent;
        const dateString = moment().format('YYYY-MM-DD_HH_mm_ss');
        const filename = `MTAD-${panelTitleText}-data-${dateString}.csv`;

        createCsvFile(csvData, filename);
    };

    const iframeSrc = useMemo(() => {
        if (!props.src.includes('tabId')) {
            return `${props.src}&tabId=${uxStore.getUniqueId}`;
        }
        return props.src;
    }, [props.src]);

    const framesDataArray = useMemo(() => {
        return Object.entries(framesData)
            .filter(([_, data]) => data.frames[0]?.data?.values.length > 0)
            .map(([key, data]) => ({
                key,
                label: data.frames[0]?.schema?.fields[1]?.name || 'N/A',
            }));
    }, [framesData]);

    const isExportVisible = useMemo(() => {
        return !!framesDataArray.some(item => framesData[item.key].frames.length > 0)
    }, [framesDataArray]);

    const Export = () => isExportVisible && (
        framesDataArray.length === 1 ? <div className='cancel-drag export-icon'
             onClick={() => handleExportClick(framesDataArray[0]?.key)}>
            <DownloadIcon/>
        </div> : <ExportDropdown />
    );

    const ExportDropdown = () => (
        <Dropdown placement="bottomRight" overlay={
            <Menu onClick={({ key }) => handleExportClick(key)}>
                {framesDataArray.map((item) => (
                    <Menu.Item key={item.key} className='cancel-drag'>
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        }>
            <div className='cancel-drag export-icon' onClick={e => e.preventDefault()}>
                <DownloadIcon/>
            </div>
        </Dropdown>
    );

    return (
        <div className='grafana-iframe-container' ref={iframeRef}>
            {!props.expanded && !showLoader && !isError &&
                <div className="iframe-action-wrapper">
                    <div className='cancel-drag expand-icon' onClick={() => setIsModalOpen(true)}><Expand/></div>
                    { !customerStore.selectedCustomer?.isDemo && <Export />}
                </div>
            }
            {isError && <div className='grafana-overly'>
                <div className="grafana-error">
                    <div className='error-title'><WarningIcon /><span>Grafana error</span></div>
                    <div className='error-msg'>
                        <div>Try refreshing the page. If this error persists,</div>
                        <div>contact your system administrator.</div>
                    </div>
                </div>
            </div>}
            {showLoader && <div className='grafana-overly'>{!uxStore.showLoader && <LoaderComponent/>}</div>}
            {props.topPanelSrc && <iframe className='grafana-iframe' src={props.topPanelSrc}  title={props.panelId}
                                          key={props.topPanelSrc}/>}
            <iframe className='grafana-iframe' src={iframeSrc} title={props.panelId}
                    key={props.panelId}/>
            {!props.expanded && <Modal
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                closable={true}
                width='70vw'
                bodyStyle={{height: '70vh'}}
                className='widget-modal'>
                <GrafanaWidget src={modalSrc} expanded={true} panelId={props.panelId} onLinkClick={props.onLinkClick}/>
            </Modal>}
        </div>);
});

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Button } from 'antd';
import './add-apn.scss';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import APNSelection from '../apn-selection/apn-selection';
import AddUrlFields, {
    createNewValuesFromMap,
    initialDestTypeToValuesMap
} from '../unauthorized-destination/add-url-fields';
import { MAX_DEST_VALUES } from '../../../../../../utils/constants';
import DestTypeGroup from '../unauthorized-destination/dest-type-group';
import { hasDuplicatesValueOnArray } from '../../../../../../utils/util';

const AddApn = observer(() => {

    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const [destinationType, setDestinationType] = useState<'white' | 'black'>('white');
    const [destTypeToValuesMap, setDestTypeToValuesMap] = useState(initialDestTypeToValuesMap);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setSelectedValues([]);
        setDestTypeToValuesMap(initialDestTypeToValuesMap);
        setDestinationType('white');
        setHasErrors(false);
    }, [isModalOpen]);

    const totalItemsInDestTypeToValues = useMemo(() => {
        return createNewValuesFromMap(destTypeToValuesMap)
    }, [destTypeToValuesMap]);

    const hasDuplicates = useMemo(() => {
        return hasDuplicatesValueOnArray(totalItemsInDestTypeToValues);
    }, [totalItemsInDestTypeToValues]);

    const handleOk = () => {
        useCaseStore.addApnList(selectedValues);
        if (useCaseStore.isAuthorizedUseCase) {
            selectedValues.forEach(apnName =>
                useCaseStore.initDestValues(apnName, [{ listType: destinationType, parameterValues: totalItemsInDestTypeToValues }]));
        }
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const okButtonDisabledProp = useMemo(() => {
        if (useCaseStore.isAuthorizedUseCase) {
            return !selectedValues.length || hasErrors || totalItemsInDestTypeToValues.length > MAX_DEST_VALUES || hasDuplicates;
        } else {
            return !selectedValues.length;
        }
    }, [selectedValues, hasErrors, totalItemsInDestTypeToValues, hasDuplicates]);


    const addApnModal = () => {
        return (
            <CustomModal
                isOpen={isModalOpen}
                width={480}
                title={"Assign APN"}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Assign"
                customClassName="assign-apn-modal"
                okButtonProps={{ disabled: okButtonDisabledProp}}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <APNSelection
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    mode="multiple"
                />
                {useCaseStore.isAuthorizedUseCase &&
                    <div className="add-url-wrapper">
                        <DestTypeGroup destinationType={destinationType} setDestinationType={setDestinationType} />
                        <div className="traffic-collect-msg">
                            <span>Note:</span>
                            After assigning an APN the system will start collecting traffic destinations.
                            You can manually define your list of authorized or unauthorized IPs and URLs,
                            or select from the collected traffic destinations later on.
                        </div>
                        <AddUrlFields
                            isDuplicate={hasDuplicates}
                            isMaxEntries={totalItemsInDestTypeToValues.length > MAX_DEST_VALUES}
                            onValuesChange={(newDestTypeToValuesMap) => {
                                setDestTypeToValuesMap((prevState) => ({ ...prevState, ...newDestTypeToValuesMap }));
                            }}
                            setHasErrors={setHasErrors}
                        />
                    </div>
                }
            </CustomModal>
        );
    };

    return (
       <>
           <Button className={'add-apn-btn secondary-btn'}
               type="primary"
               onClick={() => setIsModalOpen(true)}
               disabled={!useCaseStore.currentUseCaseModel.table && !useCaseStore.isAuthorizedUseCase}>
           Assign APN</Button>
           {isModalOpen && addApnModal()}
       </>
    );
});

export default AddApn;

export enum MenuOptions {
    DASHBOARD = 'dashboard',
    SETTINGS = 'settings',
    ANOMALIES = 'anomalies',
    INSIGHTS = 'insights',
}

export enum InsightsSubMenu {
    REPORTS = 'Reports',
    ALERTS = 'Alerts',
    ACTIVITY = 'Activity',
}

export enum InsightType {
    REPORT = 'report',
    ALERT = 'alert',
    ACTIVITY = 'activity'
}

export enum ErrorCode {
    UNAUTHENTICATED_401 = 401,
    UNAUTHORIZED_403 = 403,
    NOT_FOUND_404 = 404,
    UNPROCESSABLE_CONTENT_422 = 422,
    INTERNAL_SERVER_ERROR_500 = 500
}

export enum SettingsSubMenu {
    ANOMALIES = 'Anomalies',
    EXCEPTIONS = 'Exceptions',
    GENERAL = 'General',
}

export enum DestinationTreeColumns {
    DESTINATION = 'destination',
    IP = 'ip',
    VOLUME = 'volume',
    PERCENTAGE = 'percentage'
}

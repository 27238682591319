import axiosService from './axios.service';
import { UIBE_PREFIX } from '../utils/constants';

class UseCaseService {

    public getUseCaseModel = async (): Promise<any> => {
        const res =  await axiosService.get(`${UIBE_PREFIX}/ui-template`);
        return res?.data;
    };

    public getUseCaseValues = async (customer: string): Promise<any> => {
        const res = await axiosService.get(`${UIBE_PREFIX}/apn-preferences?customerName=${customer}`);
        return res?.data;
    }

    public getApnList = async (customer: string): Promise<any> => {
        const res = await axiosService.get(`${UIBE_PREFIX}/apn?customerName=${customer}`);
        return res?.data;
    }

    public savePreferences = async (data: any, auditLogs: AuditLogEntry[]): Promise<any> => {
        const res = await axiosService.put(`${UIBE_PREFIX}/apn-preferences?customerName=${data.oem}`, {
            ...data,
            auditLogs
        });
        return res?.data;
    }

    public getWorldRegions = async (): Promise<any> => {
        const res = await axiosService.get(`${UIBE_PREFIX}/world-regions`);
        return res?.data;
    }

    public getTrafficDests = async (oem: string, apn: string, from: number, to: number, limit: number): Promise<any> => {
        const res = await axiosService.get(`${UIBE_PREFIX}/traffic-dests?oem=${oem}&apn=${apn}&from=${from}&to=${to}&limit=${limit}`);
        return res?.data;
    }
}

const useCaseService = new UseCaseService();
export default useCaseService;

import { BaseStore } from './base.store';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import customerService from '../../services/customer.service';
import { RootStore } from './index';

export class CustomerStore extends BaseStore {
    public data = [];
    public selectedCustomer;
    public isError = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            load: action,
            selectedCustomer: observable,
            setSelectedCustomer: action,
            isError: observable,
            customerRatTypes: computed,
        });
    }

    public async load(customerName?: string) {
        try {
            this.setIsLoading(true);
            this.isError = false;
            const res = await customerService.getOemList(this.rootStore.userStore.singleCustomerName);
            const oemPreferencesList = [...res].sort((a, b) => a.label.localeCompare(b.label));
            runInAction( () => {
                this.data = oemPreferencesList;
                this.selectedCustomer = (customerName && this.data.find((c) => c.name === customerName)) ||
                    this.data[0];
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction( () => {
                this.isError = true;
                this.setIsLoading(false);
            });
        }
    }

    public setSelectedCustomer(customerName) {
        this.selectedCustomer = this.data.find((oem => oem.name === customerName));
    }

    get customerRatTypes() {
        return this.selectedCustomer?.ratTypeList?.map((ratType: string) => ratType.split('_')[1]) || [];
    }
}

import '../insights.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { useEffect } from 'react';
import { InsightsSubMenu, InsightType } from '../../../../utils/enums';
import { useLocation } from 'react-router-dom';
import { InsightsTable } from '../insights-table/insights-table';
import { formatDate } from '../../../../utils/parse';
import { useNavigate } from 'react-router';

export const Alerts = observer(() => {
    const {insightsStore, customerStore, useCaseStore} = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        insightsStore.setActiveSubMenu(InsightsSubMenu.ALERTS);
        if (customerStore.selectedCustomer && useCaseStore.insightsRecordsLimit && !customerStore.selectedCustomer?.isDemo) {
            insightsStore.loadInsights();
        }
    }, [customerStore.selectedCustomer, useCaseStore.insightsRecordsLimit]);

    function getLinkRender(idField: string) {
        return (dateTime, item, currentPage) => (
            <div onClick={() => navigate(`../../alert/${item[idField]}`, {state: {currentPage}})}>
                {formatDate(dateTime)}
            </div>
        );
    }

    const columns = [
        {
            key: 'severity', title: '', dataIndex: 'severity', widthPercentage: 1,
            render: (severity) => <div className={`severity ${severity?.toLowerCase()}`}/>
        },
        {
            key: 'name-link', title: 'ID', dataIndex: 'id', widthPercentage: 15
        },
        {key: 'use-case', title: 'Anomaly', dataIndex: 'displayName', widthPercentage: 15},
        {key: 'type', title: 'Type', dataIndex: 'alertUseCaseSubTypeTrend', widthPercentage: 10},
        {
            key: 'raised', title: 'Raised', dataIndex: 'alertRaiseDateTime', widthPercentage: 15,
            render: getLinkRender('id')
        },
        {
            key: 'restored', title: 'Restored', dataIndex: 'alertSuppressDateTime', widthPercentage: 15,
            render: getLinkRender('suppressId')
        },
        {
            key: 'apn', title: 'APN', dataIndex: 'apns', widthPercentage: 20,
            render: (apns) => <>{apns?.length > 0 ? apns.replace(/[\[\]]/g, '') : apns}</>
        }
    ];

    return (
        <div className='insights-container'>
            <div className='title'>Alerts<span className='title-summary'>{` ${insightsStore.sizeSummary}`}</span></div>
            <InsightsTable type={InsightType.ALERT} columns={columns} data={insightsStore.data.get(InsightType.ALERT)}
                           currentPage={location.state?.currentPage}/>
        </div>
    )
});

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Switch, Tooltip } from 'antd';
import AddUrl from './add-url/add-url';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/svg/download.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/svg/delete.svg';
import { ReactComponent as WarningIcon } from '../../../../../../assets/svg/yellow-warning.svg';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import * as XLSX from '@e965/xlsx';
import { saveAs } from 'file-saver';
import { DestinationList } from './destination-list';
import TableCellContent from '../table/table-cell-content';
import { SelectDestinationModal } from './select-destination-modal/select-destination-modal';
import moment from 'moment/moment';
import useCaseService from '../../../../../../services/use-case.service';
import { calculateTotalPercentage } from './destination-tree/dest-tree-data.service';
import LoaderComponent from '../../../../../shared/loader/loader-component';

export const ApnItem: React.FC<{ item: any, setActiveApn, setIsModalOpen }> = observer(({ item, setActiveApn, setIsModalOpen }) => {
    const [activeType, setActiveType] = useState<'black' | 'white' | null>(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [totalCheckedPercentage, setTotalCheckedPercentage] = useState<number | null>(null);
    const [isTotalLoading, setIsTotalLoading] = useState<boolean | null>(null);
    const { useCaseStore, customerStore } = useStores();

    const currentApnPreferences = useMemo(() => {
        return useCaseStore.apnPreferences.find(apn => apn.apnName === item.apnName);
    }, [useCaseStore.apnPreferences, item.apnName]);

    useEffect(() => {
        const whiteList = useCaseStore.destValueByType('white', item.apnName);
        const blackList = useCaseStore.destValueByType('black', item.apnName);

        if (whiteList.length > 0) {
            setActiveType('white');
        } else if (blackList.length > 0) {
            setActiveType('black');
        } else {
            setActiveType(null);
        }
    }, [currentApnPreferences]);

    useEffect(() => {
        if (activeType) {
            setIsTotalLoading(true);
            calculateTotalCheckedPercentage();
        } else {
            setTotalCheckedPercentage(0);
        }
    }, [activeType, item.apnName, currentApnPreferences]);

    const isWarningDisplay = useMemo(() => {
        if (activeType === 'white') {
            return totalCheckedPercentage < 99;
        } else if (activeType === 'black') {
            return totalCheckedPercentage > 1;
        } else {
            return false;
        }
    }, [activeType, totalCheckedPercentage]);

    const warningMessage = useMemo(() => {
        if (isWarningDisplay) {
            return activeType === 'white'
                ? 'Total traffic is below 99% for authorized list.'
                : 'Total traffic exceeds 1% for unauthorized list.';
        }
        return '';
    }, [isWarningDisplay, activeType]);

    const calculateTotalCheckedPercentage = async () => {
        const destList = useCaseStore.destValueByType(activeType, item.apnName);
        if (destList) {
            if (destList.length) {
                const fromDate = moment().utc().subtract(useCaseStore.trafficDestOffsetDays, 'days').valueOf();
                const toDate = moment().utc().valueOf();
                const limit = useCaseStore.trafficDestLimitRows;
                const trafficDests = await useCaseService.getTrafficDests(customerStore.selectedCustomer.name, item.apnName, fromDate, toDate,limit);
                const totalTrafficMb = trafficDests.reduce((sum, trafficDest) => sum + trafficDest.totalMb, 0);
                const totalPercentage = calculateTotalPercentage(destList, trafficDests, totalTrafficMb);
                setTotalCheckedPercentage(totalPercentage);
            } else {
                setTotalCheckedPercentage(0)
            }
            setIsTotalLoading(false);
        }
    };

    const handleConfirmRemoveList = () => {
        useCaseStore.changeParamValueByApn(item.apnName, `${activeType}_list_destinations`, []);
        setIsConfirmModalOpen(false);
        setActiveType(activeType === 'black' ? 'white' : 'black');
    };

    const handleToggleChange = (checked) => {
        const destList = useCaseStore.destValueByType(activeType, item.apnName);
        if (destList.length) {
            setIsConfirmModalOpen(true);
        } else {
            setActiveType(checked ? 'white' : 'black');
        }
    };

    const exportToExcel = (apn, type) => {
        const destList = useCaseStore.sortedDestValueByType(activeType, item.apnName);
        const data = destList.map(({ name, value }) => [value, name]);

        const ws = XLSX.utils.aoa_to_sheet([
            ['APN', apn],
            [''],
            [type === 'white' ? 'Authorized' : 'Unauthorized'],
            ...data,
        ]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        saveAs(new Blob([buf], { type: 'application/octet-stream' }), `${apn}.xlsx`);
    };

    const deleteApn = (apnName) => {
        setActiveApn(apnName);
        setIsModalOpen(true);
    };

    const fieldList = useMemo(() =>
        useCaseStore.currentUseCaseModel.table.tableColumn.filter(field => field.colField !== 'apnName'),
        [useCaseStore.currentUseCaseModel.table.tableColumn]);

    return (
        <div key={`${item.apnName}-${activeType}`} className="unauthorized-item">
            <div className="unauthorized-item-header">
                <div className="apn-name">{item.apnName}</div>
                <div className="fields-wrapper">
                    {fieldList.map((col) => (
                        <div key={`${col.colField}-item`} className={`item ${col.colField}`}>
                            <TableCellContent col={col} apnName={item.apnName}/>
                        </div>
                    ))}
                </div>
                <div className='unauthorized-action'>
                   <div className="switch-wrapper">
                       { activeType && <Switch
                            className={"unauthorized-switch"}
                            checkedChildren="Authorized"
                            unCheckedChildren="Unauthorized"
                            onChange={handleToggleChange}
                            checked={activeType === 'white'}
                        />}
                    </div>
                    <div className="right-actions">
                        { activeType && <>
                            <div className="percentage-display">
                                Total Traffic {isTotalLoading || totalCheckedPercentage === null ? <LoaderComponent/> :
                                <>
                                    <span>{totalCheckedPercentage.toFixed(2)}%</span>
                                    {isWarningDisplay &&
                                        <Tooltip overlayClassName="percent-warning" title={warningMessage}
                                                 placement={"topRight"}> <WarningIcon/> </Tooltip>}
                                </>
                            }
                            </div>

                            <div className='export-icon' onClick={() => exportToExcel(item.apnName, activeType)}>
                                <DownloadIcon/>
                            </div>
                        </>}
                        <AddUrl apnName={item.apnName} listType={activeType}/>
                        <SelectDestinationModal
                            apnName={item.apnName}
                            activeType={activeType}
                            preSelectedPercent={totalCheckedPercentage}
                        />
                        <div className='remove-apn' onClick={() => deleteApn(item.apnName)}>
                            <DeleteIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <DestinationList
                apnName={item.apnName}
                type={activeType}
            />
            <CustomModal
                isOpen={isConfirmModalOpen}
                width="500px"
                title="Are you sure ?"
                onOk={handleConfirmRemoveList}
                onCancel={() => setIsConfirmModalOpen(false)}
                okText="Yes"
                cancelButtonProps={{className: 'secondary-btn'}}>
                <div className="text">
                    <div>Switching the list type will remove the current list.</div>
                    <div>Do you want to proceed?</div>
                </div>
            </CustomModal>
        </div>
    )
});
